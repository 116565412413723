import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userRatingList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refGeneralConfigTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: false },
    { key: 'key', sortable: false },
    { key: 'status', sortable: false },
    { key: 'createdAt', sortable: false },
    { key: 'updatedAt', sortable: false },
    { key: 'action', sortable: false },

  ]
  const perPage = ref(10)
  const totalGeneralConfigs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const cukcukBranchOptions = ref([])
  const cukcukCategoryOptions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refGeneralConfigTable.value
      ? refGeneralConfigTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGeneralConfigs.value,
    }
  })

  const refetchData = () => {
    refGeneralConfigTable.value.refresh()
  }
  const fetchCukCukBranch = () => {
    store
      .dispatch('cukcuk/fetchCukCukBranch')
      .then(response => {
        const res = response.body.Data
        cukcukBranchOptions.value = res.map(x => ({
          label: x.Name,
          value: x.Id,
        }))
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cukcuk branch list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchCukCukCategory = () => {
    const params = {
      includeInactive: false,
      includeOtherCategory: true,
    }
    store
      .dispatch('cukcuk/fetchCukCukCategory', params)
      .then(response => {
        const res = response.body
        cukcukCategoryOptions.value = res.map(x => ({
          label: x.Name,
          value: x.Id,
        }))
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cukcuk category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  fetchCukCukBranch()
  fetchCukCukCategory()
  const fetchGeneralConfigs = (ctx, callback) => {
    store
      .dispatch('generalConfig/fetchGeneralConfigs', {
        q: searchQuery.value === '' ? null : searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const rates = response.body.data
        totalGeneralConfigs.value = response.body.meta.itemCount
        callback(rates)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching general config list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch(
    [
      currentPage,
      perPage,
      searchQuery,
    ],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 600)
    },
  )
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchGeneralConfigs,
    tableColumns,
    perPage,
    currentPage,
    totalGeneralConfigs,
    dataMeta,
    perPageOptions,
    searchQuery,
    refGeneralConfigTable,
    refetchData,
    // Extra
    cukcukBranchOptions,
    cukcukCategoryOptions,
  }
}
